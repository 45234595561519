import CurrencySelector from "./CurrencySelector";
import {IconButton, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import SyncIcon from "@mui/icons-material/Sync";
import * as React from "react";
import {useEffect, useState} from "react";
import {CurrencyCalculatorProps, ItemInterface} from "./types";
import axios from "axios";
import IranImg from '../../assets/iran.png';
import UsaImg from '../../assets/usa.png';
import SwapVertIcon from '@mui/icons-material/SwapVert';

const transformItems = (data: any): ItemInterface[] => {
  if(!data || !data.data || !data.data.cryptoCurrencyList){
    return [];
  }
  return data.data.cryptoCurrencyList.map((d: any) => {
    return {
      id: d.id,
      name: d.name,
      symbol: d.symbol,
      price_usd: d.price,
      price_toman: d.price_toman,
      icon: `https://s2.coinmarketcap.com/static/img/coins/64x64/${d.cmcId}.png`
    };
  })
}

const transformFiats = (data: any): ItemInterface[] => {
  if(!data || !data.data || !data.data.currencies){
    return [];
  }
  return data.data.currencies.map((d: any, indx: number) => {
    return {
      id: indx+1,
      name: d.name,
      symbol: d.symbol,
      price_usd: d.fiat_price,
      price_toman: d.fiat_price,
      icon: d.img
    };
  })
}

const CryptoToFiatCalculator = (props: CurrencyCalculatorProps) => {
  const [sources, setSources] = useState<ItemInterface[]>([]);
  const [selectedSource, setSelectedSource] = useState<ItemInterface | null>(null);
  const [sourceQuery, setSourceQuery] = useState('');
  const [sourceLoading, setSourceLoading] = useState(false);
  const [targets, setTargets] = useState<ItemInterface[]>([]);
  const [selectedTarget, setSelectedTarget] = useState<ItemInterface | null>(null);
  const [targetLoading, setTargetLoading] = useState(false);
  const [targetQuery, setTargetQuery] = useState('');
  const [sourceValue, setSourceValue] = useState(1);
  const [targetValue, setTargetValue] = useState(0);
  const [direction, setDirection] = useState<0 | 1>(0);
  const [lock, setLock] = useState<boolean>(false);

  useEffect(() => {
    let url = `https://appapi.ramzarz.news/api/cryptocurrency/listing`;
    if(sourceQuery){
      url = `https://appapi.ramzarz.news/api/cryptocurrency/listing?search=${sourceQuery}`;
    }

    setSourceLoading(true);
    axios.get(url)
      .then(response => response.data)
      .then((data) => {
        setSources(transformItems(data));
      })
      .finally(() => setSourceLoading(false))
  }, [sourceQuery])

  useEffect(() => {
    if(!selectedSource && sources.length){
      setSelectedSource(sources[0])
    }
  }, [sources])

  useEffect(() => {
    let usdt = sources.find((source) => {
      return source.symbol.toUpperCase() === 'USDT';
    })
    let priceToman = 1;
    if(usdt){
      priceToman = usdt.price_toman;
    }

    let data: ItemInterface[] = [
      {
        "id": '1',
        "name": "دلار آمریکا",
        "symbol": "USD",
        "icon": UsaImg,
        "price_usd": 1,
        "price_toman": priceToman,
      },
      {
        "id": '2',
        "name": "تومان",
        "symbol": "IRT",
        "icon": IranImg,
        "price_usd": 1/priceToman,
        "price_toman": 1,
      }
    ];
    setTargetLoading(true);
    setTargets(data);
    setTargetLoading(false);
  }, [targetQuery, sources])

  useEffect(() => {
    if(!selectedTarget && targets.length > 1){
      setSelectedTarget(targets[0])
    }
  }, [targets])

  useEffect(() => {
    handleSourceChange(sourceValue);
  }, [selectedSource, selectedTarget]);

  useEffect(() => {
    props.onLoad(sourceLoading || targetLoading)
  }, [sourceLoading, targetLoading])

  const handleSourceChange = (v: number) => {
    if(lock) return;
    setLock(true);
    setTargetValue(
      (selectedSource !== null && selectedTarget !== null) ? (v * (selectedSource.price_usd / selectedTarget.price_usd)) : 0
    );
    setSourceValue(v);
    setTimeout(() => {
      setLock(false)
    }, 10)
  }
  const handleTargetChange = (v: number) => {
    if(lock) return;
    setLock(true);

    setSourceValue(
      (selectedSource !== null && selectedTarget !== null) ? (v * (selectedTarget.price_usd / selectedSource.price_usd )) : 0
    );
    setTargetValue(v);

    setTimeout(() => {
      setLock(false)
    }, 10)
  }
  return (
    <>
      <CurrencySelector
        items={sources}
        selectedItem={selectedSource}
        value={sourceValue}
        onSearch={(q) => setSourceQuery(q)}
        onChange={handleSourceChange}
        onSelect={(item) => setSelectedSource(item)}
        defaultValue={1}
        decimals={sourceValue > 1 ? 0 : 6}
        loading={sourceLoading}
      />
      <Stack direction={'row'} justifyContent={'center'} >
        <Box sx={{py: 1}}>
          <SwapVertIcon />
        </Box>
      </Stack>
      <CurrencySelector
        items={targets}
        value={targetValue}
        selectedItem={selectedTarget}
        onSearch={(q) => setTargetQuery(q)}
        onChange={handleTargetChange}
        onSelect={(item) => setSelectedTarget(item)}
        defaultValue={1}
        loading={targetLoading}
        decimals={targetValue > 1 ? 0 : 6}
        popoverTitle={'ارزهای رایج'}
      />
    </>
  );
}

export default CryptoToFiatCalculator;