import CurrencySelector from "./CurrencySelector";
import {IconButton, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import SyncIcon from "@mui/icons-material/Sync";
import * as React from "react";
import {useEffect, useState} from "react";
import {CurrencyCalculatorProps, ItemInterface} from "./types";
import axios from "axios";
import SwapVertIcon from "@mui/icons-material/SwapVert";

const transformItems = (data: any): ItemInterface[] => {
  if(!data || !data.data || !data.data.cryptoCurrencyList){
    return [];
  }
  return data.data.cryptoCurrencyList.map((d: any) => {
    return {
      id: d.id,
      name: d.name,
      symbol: d.symbol,
      price_usd: d.price,
      price_toman: d.price_toman,
      icon: `https://s2.coinmarketcap.com/static/img/coins/64x64/${d.cmcId}.png`
    };
  })
}
const CryptoToCryptoCalculator = (props: CurrencyCalculatorProps) => {
  const [sources, setSources] = useState<ItemInterface[]>([]);
  const [selectedSource, setSelectedSource] = useState<ItemInterface | null>(null);
  const [sourceQuery, setSourceQuery] = useState('');
  const [sourceLoading, setSourceLoading] = useState(false);
  const [targets, setTargets] = useState<ItemInterface[]>([]);
  const [selectedTarget, setSelectedTarget] = useState<ItemInterface | null>(null);
  const [targetLoading, setTargetLoading] = useState(false);
  const [targetQuery, setTargetQuery] = useState('');
  const [sourceValue, setSourceValue] = useState(1);
  const [targetValue, setTargetValue] = useState(0);
  const [lock, setLock] = useState<boolean>(false);

  useEffect(() => {
    let url = `https://appapi.ramzarz.news/api/cryptocurrency/listing`;
    if(sourceQuery){
      url = `https://appapi.ramzarz.news/api/cryptocurrency/listing?search=${sourceQuery}`;
    }
    setSourceLoading(true);
    axios.get(url)
      .then(response => response.data)
      .then((data) => {
        setSources(transformItems(data));
      })
      .finally(() => setSourceLoading(false))
  }, [sourceQuery])

  useEffect(() => {
    if(!selectedSource && sources.length){
      setSelectedSource(sources[0])
    }
  }, [sources, selectedSource])

  useEffect(() => {
    let url = `https://appapi.ramzarz.news/api/cryptocurrency/listing`;
    if(targetQuery){
      url = `https://appapi.ramzarz.news/api/cryptocurrency/listing?search=${targetQuery}`;
    }
    setTargetLoading(true);
    axios.get(url)
      .then(response => response.data)
      .then((data) => {
        setTargets(transformItems(data));
      })
      .finally(() => setTargetLoading(false))
  }, [targetQuery])

  useEffect(() => {
    if(!selectedTarget && targets.length > 1){
      setSelectedTarget(targets[1])
    }
  }, [targets, selectedTarget])

  useEffect(() => {
    handleSourceChange(sourceValue)
  }, [selectedSource, selectedTarget])

  useEffect(() => {
    props.onLoad(sourceLoading || targetLoading)
  }, [sourceLoading, targetLoading])

  const handleSourceChange = (v: number) => {
    if(lock) return;
    setLock(true);
    setTargetValue(
      (selectedSource !== null && selectedTarget !== null) ? (v * (selectedSource.price_usd / selectedTarget.price_usd)) : 0
    );
    setSourceValue(v);
    setTimeout(() => {
      setLock(false)
    }, 10)
  }
  const handleTargetChange = (v: number) => {
    if(lock) return;
    setLock(true);

    setSourceValue(
      (selectedSource !== null && selectedTarget !== null) ? (v * (selectedTarget.price_usd / selectedSource.price_usd )) : 0
    );
    setTargetValue(v);

    setTimeout(() => {
      setLock(false)
    }, 10)
  }
  return (
    <>
      <CurrencySelector
        items={sources}
        selectedItem={selectedSource}
        value={sourceValue}
        onSearch={(q) => setSourceQuery(q)}
        onChange={handleSourceChange}
        onSelect={(item) => setSelectedSource(item)}
        defaultValue={1}
        decimals={sourceValue > 1 ? 0 : 6}
        loading={sourceLoading}
      />
      <Stack direction={'row'} justifyContent={'center'} >
        <Box sx={{py: 1}}>
          <SwapVertIcon />
        </Box>
      </Stack>
      <CurrencySelector
        items={targets}
        value={targetValue}
        selectedItem={selectedTarget}
        onSearch={(q) => setTargetQuery(q)}
        onChange={handleTargetChange}
        onSelect={(item) => setSelectedTarget(item)}
        defaultValue={1}
        loading={targetLoading}
        decimals={targetValue > 1 ? 0 : 6}
      />
    </>
  );
}

export default CryptoToCryptoCalculator;