import {Box} from "@mui/material";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

export function Percentage(props: {value: string, arrow?: boolean}){
    let color;
    let Arrow;
    if(parseFloat(props.value) > 0){
        color = 'green';
        Arrow = <ArrowRightAltIcon fontSize='small' color='success' sx={{transform: 'rotate(-90deg)', verticalAlign: 'sub'}}/>;
    }else if(parseFloat(props.value) < 0){
        color = 'red';
        Arrow = <ArrowRightAltIcon fontSize='small' color='error' sx={{transform: 'rotate(90deg)', verticalAlign: 'sub'}}/>;
    }else{
        color = 'inherit';
    }

    return (<Box component='span' sx={{color: color, display: 'inline-block', direction: 'ltr'}} >{props.value} % {
        !!props.arrow && Arrow} </Box>);
}
