import * as React from "react";
import {useEffect, useState} from "react";

export default function Toman(props: {irt: string, usd: string, symbol?: string}){
    const [usd, setUsd] = useState(props.usd)
    const [irt, setIrt] = useState(props.irt)
    const [value, setValue] = useState(() => calculateValue(props.irt))

    useEffect(() => {
        setIrt(props.irt);
    }, [props.irt])

    useEffect(() => {
        setIrt((parseFloat(props.usd) * (parseFloat(irt) / parseFloat(usd))).toString());
        setUsd(props.usd)
    }, [props.usd])

    useEffect(() => {
        setValue(calculateValue(irt))
    }, [irt])

    function calculateValue(v: string): string{
        return parseInt(v) > 1 ? new Intl.NumberFormat().format(parseInt(v)) : parseFloat(v).toFixed(6)
    }

    return <span className='iransans-fa-num'>{ value + ' ' + props.symbol}</span>;
}
