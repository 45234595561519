import * as React from 'react';
import {useCallback, useEffect, useState, Profiler} from 'react';
import {Box, Container, Divider, Grid, Link, Paper, Stack, Typography} from '@mui/material';
import axios from 'axios';
import Loading from "../Loading";
import {Link as RouterLink} from 'react-router-dom';
import DollarPrice from "../DollarPrice";
import Percentage from "../Percentage";
import Toman from "../Toman";
import Blinker from "../Blinker";
import InfiniteScroll from 'react-infinite-scroll-component';
import Ellipsis from "../Ellipsis";

interface   Currency{
    name: string,
    symbol: string,
    slug: string,
    price: string,
    price_toman: string,
    cmcRank: number,
    cmcId: number,
    percentChange24h: string,
    percentChange30d: string,
    raise?: -1 | 0 | 1
}

interface CurrenciesTableProps{
    withHeader?: boolean,
    withWs?: boolean,
    withPagination?: boolean,
    withQuery?: boolean,
    query?: string
}

function onRenderCallback(
    id: any, // the "id" prop of the Profiler tree that has just committed
    phase: any, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
    actualDuration: any, // time spent rendering the committed update
    baseDuration: any, // estimated time to render the entire subtree without memoization
    startTime: any, // when React began rendering this update
    commitTime: any, // when React committed this update
    interactions: any // the Set of interactions belonging to this update
) {
    console.log(id);
}

function CurrencyRow(props: { currency: Currency}){
    return (
        <Paper elevation={0} className={'currency-row'}>
            <Grid container my={1}>
                <Grid item xs={1} sx={{ textAlign: 'center', my: 'auto'}} className={'rank'}>
                    <Typography variant='body1'>{ props.currency.cmcRank }</Typography>
                </Grid>
                <Grid item xs={3} sx={{ textAlign: 'center'}} justifyContent="center">
                    <Stack direction='row' >
                        <Box sx={{ my: 'auto'}} className={'currency-logo'}>
                            <a href={`/currencies/${props.currency.cmcRank}/${props.currency.slug.toLowerCase()}`}>
                                <img alt={props.currency.name} width={24} src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${props.currency.cmcId}.png`} />
                            </a>
                        </Box>
                        <Stack sx={{ textAlign: 'left', ml: 1}}>
                            <Box>
                                <Link className={'currency-detail-link'} underline="none"  href={`/currencies/${props.currency.cmcRank}/${props.currency.slug.toLowerCase()}`}>
                                    <Ellipsis text={props.currency.name} />
                                </Link>
                            </Box>
                            <Box>
                                <Link className={'currency-symbol-link'} underline="none"  href={`/currencies/${props.currency.cmcRank}/${props.currency.slug.toLowerCase()}`}>
                                    <Typography className={'currency-symbol'} color={'grey'}>{ props.currency.symbol.toUpperCase() }</Typography>
                                </Link>
                            </Box>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={3} sx={{ textAlign: 'center'}} justifyContent="center">
                    <img style={{filter: 'invert(10%) sepia(207%) saturate(75%) hue-rotate(150deg) brightness(75%) contrast(120%)'}} src={`https://nobitex.ir/nobitex-cdn/charts/${props.currency.symbol.toLowerCase()}.svg`} />
                </Grid>
                <Grid item className={'currency-price'} xs={3} sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                    <Box className={'dollar-price'}>
                        <Blinker mode={props.currency.raise} >
                            <DollarPrice price={props.currency.price} />
                        </Blinker>
                    </Box>
                    <Box className={'toman-price'} sx={{ direction: 'rtl', color: 'grey'}}>
                        <Toman
                            irt={props.currency.price_toman}
                            usd={props.currency.price}
                            symbol='تومان'
                        />
                    </Box>
                </Grid>
                <Grid item className={'changes-24h'} xs={1} sx={{ textAlign: 'center', my: 'auto'}}><Percentage value={props.currency.percentChange24h} /></Grid>
            </Grid>
            <Divider />
        </Paper>
    );
}


function Currencies(props: {currencies: Currency[]}){
    return (
        <Box>
            { props.currencies.map( (currency) => (
                <CurrencyRow key={currency.cmcId} currency={currency} />
            ))}
        </Box>
    );
}

function CurrencyHead(){
    return (
        <Paper className={'currencies-head-paper'} elevation={0} sx={{ backgroundColor: 'lightgrey', borderRadius: 0}}>
            <Grid container py={1}>
                <Grid item xs={1} sx={{ textAlign: 'center', my: 'auto'}}>#</Grid>
                <Grid item xs={3} sx={{ textAlign: 'left', my: 'auto'}}>ارز</Grid>
                <Grid item xs={3} sx={{ textAlign: 'left', my: 'auto'}}>نمودار</Grid>
                <Grid item xs={3} sx={{ textAlign: 'center', my: 'auto'}}>قیمت</Grid>
                <Grid item xs={1} sx={{ textAlign: 'center', my: 'auto'}}>
                    <Box>تغییرات روزانه</Box>
                </Grid>
            </Grid>
            <Divider />
        </Paper>
    );
}
export default function CurrenciesTable() {
    const [page, setPage] = useState<number>(1)
    const [loading, setLoading] = useState<boolean>(false)
    const [finished, setFinished] = useState<boolean>(false)
    const [wsUrl, setWsUrl] = useState<string>("")
    const [ticking, setTicking] = useState<boolean>(false);
    const [currencies, setCurrencies] = useState<Currency[]>([])
    const [updates, setUpdates] = useState<any>({});
    const [assets, setAssets] = useState("ALL");


    const getCurrencies = useCallback(() => {
        setLoading(true)
        let nextPage = Math.floor(currencies.length / 40) + 1;
        axios.get(`https://appapi.ramzarz.news/api/cryptocurrency/listing?page=1`)
            .then(response => response.data)
            .then((data) => {
                setCurrencies(mergeCurrencies(currencies, data.data.cryptoCurrencyList.slice(0, 10)))

            }).catch(function(err){
            if(err.code === "ERR_BAD_REQUEST" && err.response.status === 400 && err.response.data.code === "rest_post_invalid_page_number"){
                setFinished(true);
            }

        }).finally(() => {
            setLoading(false)
        });
    }, [currencies]);

    useEffect(() => {
        setWsUrl(`wss://ws.ramzarz.news/prices?assets=${assets}`);
    }, [assets])

    useEffect(() => {
        setAssets(currencies.map((currency) => currency.slug).join(','));
    }, [currencies])

    useEffect(() => {
        if(!!wsUrl){
            const ws = new WebSocket(wsUrl);
            ws.onmessage = (event) => {
                if(!loading){
                    setUpdates(JSON.parse(event.data))
                }
            }
            return () => {
                ws.close();
            }
        }
    }, [wsUrl, loading]);

    useEffect(() => {
        let updatedCurrencies = currencies.map((currency) => {
            Object.keys(updates).forEach((currencyName) => {
                if(currency.name.toLowerCase() === currencyName.toLowerCase()){
                    currency.price = updates[currencyName];
                }
            })

            return currency;
        })

        if(!loading){
            setCurrencies(updatedCurrencies);
        }

    }, [updates, loading]);


    useEffect(() => {
        getCurrencies()
    }, []);

    function mergeCurrencies<Type extends Currency>(currentCurrencies: Type[], newCurrencies: Type[]): Type[]{
        return currentCurrencies.concat(newCurrencies.filter((crypto)=>{
            let alreadyExists =  false;
            for(let i=0;i<currentCurrencies.length;i++){
                if(currentCurrencies[i].cmcId === crypto.cmcId){
                    alreadyExists = true
                    break;
                }
            }

            return !alreadyExists;
        }))
    }

    return (
        <Container className="currencies-table custom-font-size" sx={{ direction: 'ltr'}} disableGutters maxWidth={false}>
            <CurrencyHead />
            <InfiniteScroll
                dataLength={currencies.length}
                next={getCurrencies}
                hasMore={false}
                loader={<Loading />}
                style={{overflow: 'unset'}}
            >
                <Currencies currencies={currencies} />
            </InfiniteScroll>
        </Container>
    );
}
