import * as React from 'react';
import {useState} from 'react';
import {Backdrop, Card, CardContent, CircularProgress} from '@mui/material';
import TabSelector from "./TabSelector";
import CryptoToCryptoCalculator from "./CryptoToCryptoCalculator";
import CryptoToFiatCalculator from "./CryptoToFiatCalculator";

export default function Calculator(){
    const [tab, setTab] = useState<0 | 1>(0 );
    const [cryptoLoading, setCryptoLoading] = useState(false);
    const [fiatLoading, setFiatLoading] = useState(false);

    const handleCryptoLoading = (loading: boolean) => {
      setCryptoLoading(loading)
    }
  const handleFiatLoading = (loading: boolean) => {
    setFiatLoading(loading)
  }
    return (
      <Card sx={{
        border: '2px solid #e5e7eb',
        borderRadius: '16px',
        boxShadow: '0',
        position: 'relative'
      }}>
        {
          <Backdrop
            sx={{
              color: '#fff',
              position: 'absolute',
              backdropFilter: 'blur(5px)',
              zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={cryptoLoading || fiatLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        }
        <CardContent sx={{p: 0}}>
          <TabSelector tab={tab} onChange={(newVal) => setTab(newVal)} />
        </CardContent>
        <CardContent sx={{display: tab === 0 ? 'block' : 'none'}}>
          <CryptoToFiatCalculator onLoad={handleFiatLoading}/>
        </CardContent>
        <CardContent sx={{display: tab === 1 ? 'block' : 'none'}}>
          <CryptoToCryptoCalculator onLoad={handleCryptoLoading}/>
        </CardContent>
      </Card>
    );
}