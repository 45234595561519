import {Box, CircularProgress} from "@mui/material";
import * as React from "react";

export default  function CircularIndeterminate() {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress size={30} />
        </Box>
    );
}
