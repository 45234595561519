import * as React from "react";
import {ChangeEvent, useEffect, useId, useLayoutEffect, useRef, useState} from "react";
import {
  Avatar,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Popover,
  Typography
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {NumericFormat} from "react-number-format";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import NumberInput from './NumberInput'
import {CurrencySelectorProps} from "./types";

const CurrencySelector = (props: CurrencySelectorProps) => {
  const {
    selectedItem,
    items,
    onSearch,
    onChange,
    onSelect,
    defaultValue,
    loading,
    disabled,
    decimals,
    popoverTitle
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [width, setWidth] = useState(0);
  const [query, setQuery] = useState('');
  const [value, setValue] = useState(() => {
    return defaultValue !== undefined ? defaultValue : 1;
  });
  const popoverId = useId();
  const containerId = useId();
  const elementRef = useRef<any>(null);
  const open = Boolean(anchorEl);
  const title = popoverTitle ? popoverTitle : 'ارزهای دیجیتال';

  useEffect(() => {
    if(props.value !== undefined){
      setValue(props.value);
    }
  }, [props.value])

  useLayoutEffect(() => {
    const handleResize = () => {
      // @ts-ignore
      setWidth(elementRef.current.offsetWidth);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);
  useLayoutEffect(() => {
    setWidth(elementRef?.current?.offsetWidth)
  }, [elementRef?.current?.offsetWidth]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(document.getElementById(containerId));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
    if(onSearch){
      onSearch(event.target.value);
    }
  }

  return (
    <Grid container
          id={containerId}
          ref={elementRef}
    >
      <Grid item xs={4}>
        <Button
          sx={{
            height: '100%',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            px: 1,
            bgcolor: 'rgba(226,232,240,.5)',
            fontSize: '14px',
            border: 'none',
            '&:hover': {
              border: 'none',
              bgcolor: 'rgba(226,232,240,.5)',
            }
          }}
          aria-describedby={popoverId}
          onClick={handleClick}
          fullWidth
          variant={'outlined'}
          size={'large'}
        >
          {
            selectedItem !== null && (<>
              <KeyboardArrowDownIcon sx={{color: '#859ab6'}} />
              <Typography fontWeight={'800'} color='#1e293b' mx={1} flexGrow={1} textAlign={'left'}>{selectedItem.symbol}</Typography>
              <Avatar
                sx={{width: '24px', height: '24px'}}
                src={selectedItem.icon}
              />
            </>)
          }
          {
            selectedItem === null && (<>
              <KeyboardArrowDownIcon />
              <Typography sx={{textWrap: 'nowrap', overflow: 'hidden'}} flexGrow={1}>لطفا یک گزینه انتخاب کنید</Typography>
            </>)
          }
        </Button>
      </Grid>
      <Grid item xs={8} sx={{borderRight: '2px solid white'}}>
        <NumericFormat
          value={value}
          allowNegative={false}
          decimalScale={decimals}
          thousandSeparator=","
          type={'tel'}
          disabled={disabled === true}
          customInput={NumberInput}
          onValueChange={(values, sourceInfo) => {
            let v = values.floatValue === undefined ? 0 : values.floatValue;
            if(onChange){
              onChange(v)
            }
            if(props.value === undefined){
              setValue(v);
            }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Popover
          id={popoverId}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          sx={{
            '& .MuiPaper-root': {
              width: width,
              borderRadius: '8px',
              border: '1px solid #cbd5e1',
              boxShadow: 'none'
            }
          }}
        >
          <List
            sx={{
              width: '100%',
              bgcolor: 'background.paper',
              position: 'relative',
              overflow: 'auto',
              maxHeight: 300,
              '& ul': { padding: 0 },
            }}
            subheader={<li />}
          >
            <ListSubheader disableGutters>
              <TextField
                fullWidth
                value={query}
                onChange={handleSearch}
                placeholder={'نام ارز موردنظر را وارد کنید'}
                sx={{
                  '& fieldset': {
                    border: 'none'
                  },
                  '& input': {
                    direction: 'ltr',
                    textAlign: 'left',
                    '&:-moz-placeholder': {
                      textAlign: 'right!important'
                    },
                    '&:-ms-input-placeholder': {
                      textAlign: 'right!important'
                    },
                    '&::-webkit-input-placeholder': {
                      textAlign: 'right!important'
                    }
                  }
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="end"><IconButton onClick={handleClose}><CloseIcon /></IconButton></InputAdornment>,
                  endAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                }}
              />
              <Divider />
              {
                loading && <LinearProgress color="inherit" sx={{height: '2px'}}/>
              }
            </ListSubheader>
            <ListItem sx={{textAlign: 'right'}}>
              <ListItemText sx={{color: '#475569'}}>{title}</ListItemText>
            </ListItem>
            {items.map((item) => (
              <ListItem
                selected={selectedItem !== null && item.id === selectedItem.id}
                key={item.id}
                onClick={() => {
                  if(onSelect){
                    onSelect(item)
                  }
                  handleClose()
                }}
                disableGutters
                sx={{
                  py: 0,
                  '&:hover': {
                    bgcolor: '#e2e8f0'
                  }
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <Avatar
                      sx={{width: '16px', height: '16px'}}
                      src={item.icon}
                    />
                    <Typography mx={1} color={'#1e293b'} fontWeight={'500'}>{item.symbol}</Typography>
                  </ListItemIcon>
                  <ListItemText sx={{'& .MuiTypography-root': {color: '#94a3b8'}, margin: 0}} primary={item.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Popover>
      </Grid>
    </Grid>
  );
}

export default CurrencySelector;