import * as React from "react";
import {PropsWithChildren} from "react";
import {Box} from "@mui/material";

export default function Blinker(props: PropsWithChildren<{mode: -1 | 0 | 1 | undefined}>){
    let color = 'black';
    if(props.mode === -1){
        color = 'red';
    }else if(props.mode === 1){
        color = 'green';
    }else{
        color = 'black';
    }

    return (
        <Box component='span' color={color}>{ props.children }</Box>
    );
}
