import * as React from "react";
import TextField, {TextFieldProps} from "@mui/material/TextField";

const NumberInput = (props: TextFieldProps) => {
  return (
    <TextField
      {...props}
      variant='outlined'
      fullWidth
      sx={{
        direction: 'ltr',
        textAlign: 'left',
        '& fieldset.MuiOutlinedInput-notchedOutline': {
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0,
          border: 'none',
          top: '-5.5px',
        },
        '& input.MuiInputBase-input': {
          fontSize: '14px',
          fontWeight: '800',
          color: '#1e293b',
          bgcolor: 'rgba(226,232,240,.5)',
          borderBottomLeftRadius: '8px',
          borderTopLeftRadius: '8px',
        }
      }}
    />
  );
}

export default NumberInput;