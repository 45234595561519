import {useEffect, useState} from "react";
import {Box} from "@mui/material";

export default function Ellipsis(props: {text: string}){
    const [width, setWidth] = useState(getWindowWidth());
    const [text, setText] = useState(props.text);

    useEffect(() => {
        function handleWindowResize() {
            setWidth(getWindowWidth());
        }

        window.addEventListener('resize', handleWindowResize);


        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        if(width < 500){
            setText(truncate(props.text, 12))
        }else if(width < 600){
            setText(truncate(props.text, 14))
        } else{
            setText(props.text);
        }
    }, [width, props.text])

    function getWindowWidth() {
        const {innerWidth} = window;

        return innerWidth;
    }
    function truncate(str: string, n: number){
        return (str.length > n) ? str.slice(0, n-1) + '...' : str;
    }
    return <Box component='span' sx={{whiteSpace: 'nowrap'}}>{ text }</Box>;
}
