import {Box} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";

function ColorfulNumber(props: {n: string, sign?: string}){
    const [value, setValue] = useState(props.n);
    const [color, setColor] = useState('black');

    useEffect(() => {
        if(parseFloat(props.n) > parseFloat(value)){
            setColor('green');
        }else if(parseFloat(props.n) < parseFloat(value)){
            setColor('red');
        }else{
            setColor('black')
        }
        setValue(props.n)
    }, [props.n])
    useEffect(()=>{
        setTimeout(()=>{
            setColor('black');
        }, 1000)
    }, [color])

    function formatter(v: string): string{
        return parseInt(v) >= 1 ? Intl.NumberFormat().format(parseFloat(v)) : parseFloat(parseFloat(v).toFixed(6)).toString()
    }

    return <Box component='span' color={color} sx={{ display: 'inline-block', direction: 'ltr', textAlign: 'left'}}>{ props.sign } { formatter(value) }</Box>
}

export default function DollarPrice(props: {price: string}){
    const price = parseFloat(props.price) >= 1 ? parseFloat(props.price).toFixed(2) :  parseFloat(props.price).toFixed(6);
    return (
        <Box component='span' sx={{ display: 'inline-block', direction: 'ltr', textAlign: 'left'}}>
            <ColorfulNumber n={price} sign='$' />
        </Box>
    )
}
