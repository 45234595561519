import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {ThemeProvider} from '@emotion/react';
import {createTheme, responsiveFontSizes} from '@mui/material';
import Calculator from "./components/Calculator";
import CurrenciesTableV1 from "./components/CurrenciesTableV1";

const theme = responsiveFontSizes(createTheme({
  direction: 'rtl',
  palette: {
    secondary: {
      main: '#fafafa'
    }
  },
  typography: {
    fontSize: 11,
    fontFamily: [
      'IRANSansX',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          height: '40px',
          fontSize: '0.4875rem'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Iransans';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Iransans'), local('Iransans-Regular'), url(/fonts/IRANSansWeb.woff2) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
}));


const calculater = ReactDOM.createRoot(
  document.getElementById('calculater-component') as HTMLElement
);
calculater.render(
  <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Calculator />
      </ThemeProvider>
  </React.StrictMode>
);

const currencies = ReactDOM.createRoot(
  document.getElementById('currencies-table-component') as HTMLElement
);
currencies.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CurrenciesTableV1 />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
