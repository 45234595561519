import * as React from "react";
import {Tab, Tabs} from "@mui/material";
import {TabSelectorProps} from "./types";

const TabSelector = (props: TabSelectorProps) => {
  const {tab, onChange} = props;

  return (
    <Tabs
      value={tab}
      onChange={(event: React.SyntheticEvent, newTab: 0 | 1) => {
        if(onChange){
          onChange(newTab);
        }
      }}
      variant="fullWidth"
      centered
      TabIndicatorProps={{
        hidden: true
      }}
    >
      <Tab
        disableRipple
        sx={{
          borderBottomLeftRadius: '16px',
          backgroundColor: '#f1f5f9',
          '&.Mui-selected': {
            backgroundColor: '#fff',
            fontWeight: '800'
          }
        }}
        label="کوین به ارز رایج" />
      <Tab
        disableRipple
        sx={{
          borderBottomRightRadius: '16px',
          backgroundColor: '#f1f5f9',
          '&.Mui-selected': {
            backgroundColor: '#fff',
            fontWeight: '800'
          }
        }}
        label="کوین به کوین" />
    </Tabs>
  );
}

export default TabSelector;